<template>
    <modal ref="modalCrearEmpacador" titulo="Crear Empacador" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="postEmpacador">
        <cargando v-if="cargando" />
        <ValidationObserver ref="validacion">
            <div class="row mx-0 mb-3 justify-center px-5">
                <div class="col-auto px-0">
                    <slim-cropper ref="fotoEmpacador" :options="slimOptions" class="border cr-pointer slim-styles" />
                    <p class="text-gris2 f-14 text-center mt-2">
                        Foto
                    </p>
                </div>
                <div class="col pl-4 pr-0">
                    <p class="text-general pl-3 f-12">
                        Nombre Completo
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:25" name="Nombres">
                        <el-input v-model="nombres" placeholder="Nombres y Apellidos" class="w-100" maxlength="25" show-word-limit size="small" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mb-3 mt-4 px-5">
                <div class="col pl-0">
                    <p class="text-general pl-3 f-12">
                        Añadir cedis a asociado
                    </p>
                    <el-select v-model="cedisSeleccionado" placeholder="Seleccionar" class="w-100" size="small" @change="seleccionarCedis">
                        <el-option
                        v-for="(cedi,index) in cedis"
                        :key="index"
                        :label="cedi.nombre"
                        :value="cedi.id"
                        />
                    </el-select>
                </div>
                <div class="col pr-0 pt-2">
                    <template v-if="cedisSeleccionados.length > 0">
                        <div v-for="(cedi,index) in cedisSeleccionados" :key="index" class="row mx-0 mt-3 mb-3">
                            <i v-if="cedi.principal === 1" class="icon-star text-warning f-15" />
                            <el-tooltip content="Seleccionar como principal" placement="bottom" effect="light">
                                <i v-if="cedi.principal === 0" class="icon-star f-15 cr-pointer" @click="seleccionarCedisPrincipal(cedi.id, index)" />
                            </el-tooltip>
                            <div class="col-8 tres-puntos text-general f-15">
                                {{ cedi.nombre }}
                            </div>
                            <el-tooltip content="Eliminar" placement="bottom" effect="light">
                                <i class="icon-cancel f-15 text-general cr-pointer" @click="eliminarCedi(index)" />
                            </el-tooltip>
                        </div>
                        <span v-if="cedisPrincipal === ''" class="text-danger w-100 f-11">Debe seleccionar un cedis como principal</span>
                    </template>
                    <template v-else>
                        <span class="text-danger w-100 f-11">No ha seleccionado ningún cedis</span>
                    </template>
                </div>
            </div>
            <div class="row mx-0 mb-3 px-5">
                <div class="col pl-0">
                    <p class="text-general pl-3 f-12">
                        Número de teléfono
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required" name="Número de teléfono">
                        <el-input v-model="numeroTelefono" placeholder="Número de teléfono" size="small" class="w-100 soloNumeros" @focus="soloNumeros" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col pr-0">
                    <p class="text-general pl-3 f-12">
                        Correo electrónico
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="max:70" name="Correo Electrónico">
                        <el-input v-model="correoElectronico" placeholder="Correo Electrónico" maxlength="70" size="small" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mb-3 px-5">
                <div class="col pl-0">
                    <p class="text-general pl-3 f-12">
                        Contraseña
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:120" name="Contraseña">
                        <el-input v-model="contrasena" placeholder="Contraseña" maxlength="120" size="small" class="w-100" @blur="validarContrasenas" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col pr-0">
                    <p class="text-general pl-3 f-12">
                        Repetir Contraseña
                    </p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:120" name="Repetir Contraseña">
                        <el-input v-model="repContrasena" placeholder="Repetir Contraseña" size="small" class="w-100" @blur="validarContrasenas" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        <span v-if="contrasenas_mal" class="text-danger w-100 f-11">Contraseñas no coinciden</span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '~/services/empacadores/empacadores'
export default {
    data(){
        return {
            cargando: false,
            contrasenas_mal: false,
            cedis: [],
            cedisSeleccionados: [],
            nombres: '',
            numeroTelefono: '',
            correoElectronico: '',
            contrasena: '',
            repContrasena: '',
            cedisSeleccionado: '',
            cedisPrincipal: '',
            slimOptions: {
                ratio: '1:1',
                label:'Subir foto',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    mounted(){
        this.getCedis();
    },
    methods: {
        validar(){
            if(this.cedisSeleccionados.length < 1)return false;
        },
        async getCedis(){
            try{
                const {data} = await Service.getCedis()
                this.cedis = data.cedis;
            } catch (e){
                this.error_catch(e)
            }
        },
        async postEmpacador(){
            try{
                this.cargando = true;
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;
                if(this.cedisSeleccionados.length < 1)return this.cargando = false;
                if(this.cedisPrincipal === '')return this.cargando = false;
                const archivo = this.$refs.fotoEmpacador.instanciaCrop.dataBase64.output.image;
                const form = {
                    nombre: this.nombres,
                    telefono: this.numeroTelefono,
                    correo: this.correoElectronico,
                    password: this.contrasena,
                    foto: archivo,
                    id_cedis: this.cedisPrincipal,
                    cedisSeleccionados: this.cedisSeleccionados
                };
                const {data} = await Service.postEmpacador(form);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.limpiar();
                this.$emit('actualizar');
                this.$refs.modalCrearEmpacador.toggle();
            } catch (e){
                this.error_catch(e);
            } finally{
                this.cargando = false;
            }
        },
        seleccionarCedis(){
            let existe = this.cedisSeleccionados.filter((item) => item.id === this.cedisSeleccionado);
            if(existe.length > 1)return
            let data = this.cedis.find((item) => item.id === this.cedisSeleccionado);
            this.cedisSeleccionados.push({
                'id': data.id,
                'nombre': data.nombre,
                'principal': 0
            });
            this.cedisSeleccionado = "";
        },
        seleccionarCedisPrincipal(id, index){
            let principal = this.cedisSeleccionados.find((item) => item.principal === 1);
            if(principal){
                let indexPrincipal = this.cedisSeleccionados.findIndex(e => e.id === principal.id)
                this.cedisSeleccionados[indexPrincipal].principal = 0;
            }
            this.cedisSeleccionados[index].principal = 1;
            this.cedisPrincipal = id;
        },
        soloNumeros(){
            $('.soloNumeros').keypress(function(event){
                // Bloqueo de letras y caracteres especiales
                let char = String.fromCharCode(event.keyCode);
                let pattern = '[a-zA-Z|°!"#$%&/()=?¿¡]';
                if (char.match(pattern)){
                    return false;
                }
            });
        },
        eliminarCedi(index){
            let id = this.cedisSeleccionados[index].id;
            let principal = this.cedisSeleccionados.find((item) => item.principal === 1);
            if(principal){
                if(principal.id === id){
                    this.cedisPrincipal = "";
                }
            }
            this.cedisSeleccionados.splice(index, 1);
        },
        validarContrasenas(){
            if(this.repContrasena === '')return;
            if(this.contrasena.toLowerCase() != this.repContrasena.toLowerCase()){
                return this.contrasenas_mal = true;
            }
            return this.contrasenas_mal = false;
        },
        limpiar(){
            this.cedisSeleccionados = [];
            this.nombres = '';
            this.numeroTelefono = '';
            this.correoElectronico = '';
            this.contrasena = '';
            this.repContrasena = '';
            this.cedisSeleccionado = '';
            this.cedisPrincipal = '';
            this.$refs.validacion.reset();
            this.$refs.fotoEmpacador.instanciaCrop.remove();
        },
        toggle(){
            this.limpiar();
            this.$refs.modalCrearEmpacador.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.slim-styles{
    height:162px;
    width:162px;
    background-color:#F5F5F5;
    border-radius:6px;
}
</style>